const letters = (() => {
  const caps = [...Array(26)].map((_val, i) => String.fromCharCode(i + 65))
  return caps
})()

/**
 *
 * @param index 0-25
 * @returns Corresponding alphabet letter in capital case or an empty string if index not found
 */
export function getAlphabetBy(index: number): string | '' {
  const letter = letters[index]
  if (!letter)
    return ''

  return letter
}
